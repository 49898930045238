<template>
  <image-banner
    src="/images/cbc-map.png"
    alt="Clifton Bootcamp location"
  />
  <page-section
    v-fadeIn="{duration: 0.6, delay: 0.2 }"
  >
    <section-header>Where to find us</section-header>
    <div class="px-6 md:pl-24 lg:pl-48 text-left">
      <p class="px-8 py-4 text-lg md:text-xl">
        <span class="font-semibold">6am + evening sessions:</span> Opposite the water tower. what3words: <a
          href="https://what3words.com/rank.chefs.caller"
          rel="nofollow noopener"
          target="_blank"
        >///rank.chefs.caller</a>
      </p>
      <p class="px-8 py-4 text-lg md:text-xl">
        <span class="font-semibold">9am sessions:</span> Stoke Road. what3words: <a
          href="https://what3words.com/shots.rescue.wallet"
          rel="nofollow noopener"
          target="_blank"
        >///shots.rescue.wallet</a>
      </p>
      <p class="px-8 py-4 text-lg md:text-xl">
        <span class="font-semibold">12pm sessions</span>: Top of Ivywell Road, Sea Walls. what3words: <a
          href="https://what3words.com/torch.hook.edgy"
          rel="nofollow noopener"
          target="_blank"
        >///torch.hook.edgy</a>
      </p>
    </div>
  </page-section>
</template>
