<template>
  <div class="w-full border-cbc relative">
    <img
      :src="props.src"
      :alt="props.alt"
      class="w-full h-48 sm:h-72 lg:h-96 object-cover"
      preload
    >
    <div
      v-if="props.mask"
      class="absolute inset-0 bg-black opacity-40"
    />
    <div
      v-if="props.text"
      class="absolute inset-0 flex items-center justify-center"
    >
      <h2 class="text-white text-xl md:text-5xl font-bold">
        {{ props.text }}
      </h2>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults( defineProps<{
    src: string
    alt: string
    mask?: boolean
    text?: string
  }>(),{
    mask: false,
    text: undefined,
  } )
</script>
